import { Routes } from '@angular/router';

export const routes: Routes = [


  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadComponent: () => import('./home/home.component').then((m) => m.HomeComponent)
  },
  {
    path:'driver',
    loadComponent: ()=> import('./driver/driver.component').then((m)=> m.DriverComponent)
  },
  {
  path:'map',
  loadComponent: ()=> import('./map/map.component').then((m)=> m.MapComponent),
  },
  {
    path:'success',
    loadComponent: ()=> import('./success/success.component').then((m)=> m.SuccessComponent)
  },
  {
    path:'driver',
    loadComponent: ()=> import('./driver/driver.component').then((m)=> m.DriverComponent)
  },
  {
    path: 'auth',
    loadComponent: () => import('./auth/auth.page').then( m => m.AuthPage)
  }

];